@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --gradient: linear-gradient( 136deg, rgb(54 241 205) 0%, rgb(57 160 237) 50%, rgb(76 96 133) 100%);
  --primary: #39A0ED;
  --secondary: #21272a;
  --color: rgb(40 40 40 / 92%);
  --color-50: rgb(33 39 42 / 50%);
  --color-100: rgb(33, 39, 42);
  --font-size-h2: 40px;
  --font-size-h3: 18px;
  --font-size-dashboard-h3: 20px;
  --font-size-dashboard-h4: 18px;
  --font-size-dashboard-h5: 16px;
  --font-size-text: 16px;
}

body {
  margin: 0;
  color: var(--color);
  font-size: 14px;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6, a, p, span, label, select, option, input, textarea{
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

label, select, option, input, textarea, label a, [role="button"].MuiSelect-select{
  font-size: 14px !important;
  font-weight: normal;
}

h2{
  font-size: var(--font-size-h2);
}

h3{
  font-size: var(--font-size-h3);
}

p.content {
  font-size: var(--font-size-text);
  font-weight: normal;
  line-height: initial;
  letter-spacing: initial;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

button:not(.MuiButton-outlined, .MuiTab-root, .not-white, .MuiPaginationItem-colorSecondary){
  color: #fff !important;
  text-transform: none !important;
}

button.btn {
  text-transform: none !important;
}

button.MuiButton-text{
  text-transform: none !important;
  color: var(--secondary) !important;
}

.img-responsive{
  width: 100%;
  max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  appearance: initial !important;
  border-radius: 4px !important;
  background-color: initial !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.slider-skipper-card .slick-list{
  overflow: hidden;
}

.slider-skipper-card .slick-track{
  display: flex;
}

.effect-line{position: relative; opacity: 1 !important;}

.effect-line::after {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  height: 28px;
  background: linear-gradient( to right, var(--primary), var(--primary) ), linear-gradient( to right, #36f1cd, #39a0ed, #4c6085 ) !important;
  background-size: 100% 3px, 0 3px !important;
  background-position: 100% 100%, 0 100% !important;
  background-repeat: no-repeat !important;
  transition: background-size 400ms !important;
}

.effect-line:hover::after {
  background-size: 0 3px, 100% 3px !important;
}

.MuiChip-root.cancel {
  background: #f8bbd08c;
  color: #E91E63;
}

.MuiChip-root.unconfirmed {
  background: #FFF3E0;
  color: #FF9800;
}

.MuiChip-root.accept {
  background: #E8F5E9;
  color: #4CAF50;
}

.MuiChip-root.booking-modify {
  background: #E3F2FD;
  color: var(--primary);
}